import clx from 'clsx';
import { urnsToDisplayName } from '@qloo/categories';
import { ArrowForwardIos } from '@mui/icons-material';
import React from 'react';
import { Button } from '@/components';
import EntitiesList from '@/components/Omnisearch/EntitiesList';
import CategoryDataErrorWrapper from '@/screens/Dashboard/DashboardPanel/PanelBodyExplore/CategoryDataError';
import CategoryDataTags from './CategoryDataTags';
import styles from './PanelBodyExplore.module.scss';

const CategoryData = ({
  gptResult, tagsResult, category, onSelect, entitiesResult, error, tagTypes, tagsFetcher,
}) => {
  const hasError = Boolean(error);
  if (hasError) {
    return <CategoryDataErrorWrapper category={category} onSelect={onSelect} error={error} />;
  }

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
    <div
      key={category}
      className={clx(
        styles.categoryData,
      )}
      onClick={onSelect(category)}
    >
      <div
        className={clx(styles.overlay)}
      />
      <div
        className={styles.categoryHeader}
      >
        <span>{urnsToDisplayName(category)}</span>
        <Button color="link"><ArrowForwardIos /></Button>
      </div>
      <span className={styles.categoryDescription}>
        {gptResult}
      </span>
      <div className={styles.separator} />
      <CategoryDataTags category={category} tagsResult={tagsResult} tagTypes={tagTypes} tagsFetcher={tagsFetcher} />
      <div className={styles.separator} />
      {entitiesResult?.results?.entities && (
        <EntitiesList
          resetPadding
          entities={entitiesResult.results.entities}
          entitiesGridClassName={styles.entitiesGridClassName}
        />
      )}
    </div>
  );
};

export default CategoryData;
