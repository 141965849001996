import clx from 'clsx';
import React from 'react';
import CategoryDataError from '@/screens/Dashboard/DashboardPanel/PanelBodyExplore/CategoryDataErrorFallback';
import styles from './PanelBodyExplore.module.scss';

const CategoryDataErrorWrapper = ({ category, onSelect, error }) => {
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
    <div
      key={category}
      className={clx(
        styles.categoryData,
        styles.categoryDataConcise,
      )}
      onClick={onSelect(category)}
    >
      <CategoryDataError error={error} />
    </div>
  );
};

export default CategoryDataErrorWrapper;
