import React, { useEffect, useMemo } from 'react';
import { findPriority, useCategoryPerms } from '@v1/lib/usePermissions';
import clx from 'clsx';
import { useSelector } from 'react-redux';
import { Skeleton } from '@/components';
import CANONICAL_TAG from '@/utils/canonicalTag';
import { useGetTagsQuery } from '@/store/apis/tagsApi';
import { paramNames } from '@/constants';
import { useGetInsightQuery } from '@/store/apis/insightsApi';
import useGPTExplainRecs from '@/hooks/useGPTExplainRecs';
import { serializeInsightsParams } from '@/utils';
import formatCategoryUrnToCssVar from '@/utils/formatCategoryUrnToCssVar';
import CategoryData from '@/screens/Dashboard/DashboardPanel/PanelBodyExplore/CategoryData';
import ResumedCategoryData from '@/screens/Dashboard/DashboardPanel/PanelBodyExplore/ResumedCategoryData';
import styles from './PanelBodyExplore.module.scss';
import { tagTypesBySelectedCategory } from './constants';
import useTagsFetcher from './useTagsFetcher';
import validateQueryError from '../helpers/validateQueryError';

const CategoryDataSkeleton = ({ conciseView }) => (
  <div className={styles.categoryData}>
    <div className={styles.overlay} />
    <div className={styles.categoryHeader}>
      <Skeleton variant="text" width={150} height={30} />
      {/* eslint-disable-next-line no-inline-styles/no-inline-styles */}
      <Skeleton variant="rectangular" width={30} height={30} style={{ marginLeft: 'auto' }} />
    </div>
    <Skeleton variant="text" width="100%" height={20} />
    <div className={styles.separator} />
    <Skeleton variant="rectangular" width="100%" height={50} />
    <div className={styles.separator} />
    {!conciseView && <Skeleton variant="rectangular" width="100%" height={150} />}
  </div>
);

const CategoryDataView = ({
  category, onSelect, baseParams, conciseView,
}) => {
  const filters = useMemo(() => ({
    parentType: category, take: 5, tags: CANONICAL_TAG, ...baseParams,
  }), [category, baseParams]);

  const {
    data: tagsResult,
    isLoading: isLoadingTags,
    error: tagsError,
  } = useGetTagsQuery(filters);

  const serializedInsightsParams = useMemo(() => {
    const tempParams = {
      [paramNames.filterType]: category,
      [paramNames.take]: 3,
    };

    return serializeInsightsParams(baseParams, tempParams);
  }, [baseParams, category]);

  const {
    data: entitiesResult,
    isLoading: isLoadingEntities,
    error: insightsError,
  } = useGetInsightQuery(serializedInsightsParams);

  const { result: gptResult, isLoading: isLoadingGPT, error: gptError } = useGPTExplainRecs({ category, baseParams });

  const errorData = useMemo(() => {
    const errors = [insightsError, tagsError, gptError];
    return validateQueryError({ errors });
  }, [insightsError, tagsError, gptError]);
  const tagsFetcher = useTagsFetcher();
  const tagTypes = tagTypesBySelectedCategory[category] || [];

  useEffect(() => {
    if (tagTypes.length) {
      tagsFetcher.fetchByTagTypes({
        baseParams,
        pagination: {
          [paramNames.offset]: 0,
          [paramNames.take]: 9,
        },
        selectedCategory: category,
        tagTypes,
      });
    }
  }, [baseParams, category]);

  if (isLoadingEntities || isLoadingGPT || isLoadingTags) {
    return <CategoryDataSkeleton conciseView={conciseView} />;
  }

  const categoryColor = `var(--category-${formatCategoryUrnToCssVar(category)})`;

  return (
    <>
      {!conciseView && (
        <CategoryData
          category={category}
          onSelect={onSelect}
          tagsResult={tagsResult}
          entitiesResult={entitiesResult}
          gptResult={gptResult}
          error={errorData}
          tagTypes={tagTypes}
          tagsFetcher={tagsFetcher}
        />
      )}
      {conciseView && (
        <ResumedCategoryData
          category={category}
          onSelect={onSelect}
          tagsResult={tagsResult}
          entitiesResult={entitiesResult}
          categoryColor={categoryColor}
          error={errorData}
        />
      )}
    </>
  );
};

const CategoriesDataList = ({
  onSelect,
  baseParams,
}) => {
  const categories = Object.keys(useCategoryPerms()).sort((a, b) => {
    const aPriority = findPriority(a);
    const bPriority = findPriority(b);
    if (aPriority !== bPriority) {
      return aPriority - bPriority;
    }
    return a.localeCompare(b);
  });
  const { isExploreConciseView } = useSelector((state) => state.dashboards);

  return (
    <div className={styles.mainContainer}>
      <div className={clx(
        styles.categoriesDataListGrid,
        { [styles.categoriesDataListGridConcise]: isExploreConciseView },
      )}
      >
        {categories.map((category) => {
          return (
            <CategoryDataView
              baseParams={baseParams}
              category={category}
              onSelect={onSelect}
              conciseView={isExploreConciseView}
            />
          );
        })}
      </div>
    </div>
  );
};

export default CategoriesDataList;
