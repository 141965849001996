import { AlertTriangle } from 'react-feather';
import React from 'react';
import { Button, Card } from '@/components';
import extractErrorMessage from '@/utils/extractErrors';

import styles from './PanelBodyExplore.module.scss';

const CategoryDataError = ({ error, resetErrorBoundary }) => {
  const errorMessage = extractErrorMessage(error);

  return (
    <Card
      className={styles.errorContainer}
      title={<><AlertTriangle color="hsl(var(--status-danger))" /> Error:</>}
      actions={[
        <Button variant="link" noPadding href="mailto:saas@qloo.com?subject=query help&body=Hi!, I need help with...">
          Contact Support
        </Button>,
        <Button onClick={resetErrorBoundary}>Retry</Button>,
      ]}
    >
      <div className={styles.errorMessage}>
        <pre>{errorMessage}</pre>
      </div>
    </Card>
  );
};

export default CategoryDataError;
