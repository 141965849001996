import React, { useEffect, useMemo, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { Table, Tag } from '@/components';
import { paramNames } from '@/constants';
import { affinityColumn, nameColumn } from '@/screens/Dashboard/DashboardPanel/helpers/getColumns';
import { useGetInsightQuery } from '@/store/apis/insightsApi';
import { serializeInsightsParams } from '@/utils';
import styles from './PanelBodyExplore.module.scss';
import useTagsFetcher from './useTagsFetcher';
import formatTagType from './formatTagType';
import { barChartTagTypesBitmap } from './constants';

const defaultTagType = 'Default';

const TagCell = ({ row, selectedCategory }) => {
  return (
    <Tag
      noBorder
      noHover
      key={row.tagId}
      useCategoryColor
      {...row.original}
      isSelected={false}
      showSelectedIcon={false}
      type={row?.original?.subtype}
      category={selectedCategory}
    />
  );
};

const SelectedCategoryContentTagsTabs = ({
  baseParams,
  pagination,
  selectedCategory,
  tagTypes,
}) => {
  const serializedTagsInsightsParams = useMemo(() => {
    const tempParams = {
      [paramNames.filterType]: 'urn:tag',
      [paramNames.filterParentTypes]: selectedCategory,
      offset: pagination[paramNames.offset],
      take: pagination[paramNames.take] + 1,
    };

    return serializeInsightsParams(baseParams, tempParams);
  }, [baseParams, pagination, selectedCategory]);

  const {
    data: tagsResult,
    isLoading: isTagsLoading,
    error: insightsTagsError,
  } = useGetInsightQuery(serializedTagsInsightsParams);

  const tags = useMemo(
    () => (insightsTagsError ? [] : tagsResult?.results?.tags.slice(0, pagination[paramNames.take])),
    [tagsResult?.results?.tags, insightsTagsError, pagination],
  );

  const [tabValue, setTabValue] = useState(defaultTagType);
  const handleTabChange = (e, nextTabValue) => {
    setTabValue(nextTabValue);
  };

  const tagsFetcher = useTagsFetcher();
  useEffect(() => {
    tagsFetcher.fetchByTagTypes({
      baseParams,
      pagination,
      selectedCategory,
      tagTypes,
    });
  }, [baseParams, pagination, selectedCategory]);

  const tagTypesWithDefault = [defaultTagType, ...tagTypes].filter((tagType) => !barChartTagTypesBitmap[tagType]);

  return (
    <div className={styles.tableWithTitle}>
      <div className={styles.tableTitle}>Tags</div>
      <TabContext value={tabValue}>
        <Tabs onChange={handleTabChange} scrollButtons value={tabValue}>
          {tagTypesWithDefault.map((tagType) => {
            const label = tagType === defaultTagType ? 'Default' : formatTagType(tagType);
            return (
              <Tab label={label} value={tagType} />
            );
          })}
        </Tabs>
        {tagTypesWithDefault.map((tagType) => {
          const isLoading = tagType === defaultTagType ? isTagsLoading : tagsFetcher.state[tagType]?.isLoading || false;
          const result = tagType === defaultTagType ? tags || [] : tagsFetcher.state[tagType]?.result || [];
          return (
            <TabPanel value={tagType}>
              <Table
                isLoading={isLoading}
                columns={[
                  {
                    ...nameColumn,
                    // eslint-disable-next-line react/no-unstable-nested-components
                    Cell: (props) => <TagCell {...props} selectedCategory={selectedCategory} />,
                  },
                  affinityColumn,
                ]}
                data={result}
              />
            </TabPanel>
          );
        })}
      </TabContext>
    </div>
  );
};

export default SelectedCategoryContentTagsTabs;
