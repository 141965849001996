// TODO: Fix dependency cycles
/* eslint import/no-cycle: 0 */
import qs from 'qs';
import {
  fieldByParamName, fieldTypes, interestTypes, paramNames,
} from '@/constants';
import getIsFieldValueEmpty from './getIsFieldValueEmpty';

const serializeAudiences = (value) => {
  if (value.length) {
    return { [paramNames.signalDemographicsAudiences]: value.join(',') };
  }

  return {};
};

const serializeOmnisearch = (value) => {
  const [entityIds, tagIds] = value.reduce((acc, item) => {
    if (item.type === interestTypes.entity) {
      return [[...acc[0], item.id], acc[1]];
    }

    if (item.type === interestTypes.tag) {
      return [acc[0], [...acc[1], item.id]];
    }

    return acc;
  }, [[], []]);

  const result = {};

  if (entityIds.length) {
    result[paramNames.signalInterestsEntities] = entityIds.join(',');
  }

  if (tagIds.length) {
    result[paramNames.signalInterestsTags] = tagIds.join(',');
  }

  return result;
};

const serializeRange = ({ paramName, value }) => {
  const result = {};
  const [min, max] = value && Array.isArray(value) ? value : [null, null];

  if (typeof min === 'number') {
    result[`${paramName}.min`] = min;
  } else {
    result[`${paramName}.min`] = Number(min);
  }

  if (typeof max === 'number') {
    result[`${paramName}.max`] = max;
  } else {
    result[`${paramName}.max`] = Number(max);
  }

  return result;
};

const serializeFilterTags = (value) => {
  const result = {};

  const [include, exclude] = Object.entries(value).reduce((acc, [id, status]) => {
    if (status === 'include') {
      return [[...acc[0], id], acc[1]];
    }

    if (status === 'exclude') {
      return [acc[0], [...acc[1], id]];
    }

    return acc;
  }, [[], []]);

  if (include.length) {
    result[paramNames.filterTags] = include.join(',');
  }

  if (exclude.length) {
    result[paramNames.filterExcludeTags] = exclude.join(',');
  }

  return result;
};

const serializeLocation = ({ paramName, value }) => {
  // For location, we need to separate radius from location
  const { radius, value: location } = value;

  // Base params case
  if (paramName === paramNames.signalLocation) {
    return {
      [paramNames.signalLocation]: location,
      [paramNames.signalLocationRadius]: radius,
    };
  }

  // Panel params case
  return {
    [paramNames.filterLocation]: location,
    [paramNames.filterLocationRadius]: radius,
  };
};

const serializeValue = ({ fieldType, paramName, value }) => {
  switch (fieldType) {
    case fieldTypes.rangeInputs:
    case fieldTypes.ratingRange:
    case fieldTypes.slider:
    case fieldTypes.sliderWithInputs:
      return serializeRange({ paramName, value });
    case fieldTypes.omnisearch:
      return serializeOmnisearch(value);
    case fieldTypes.filterTags:
      return serializeFilterTags(value);
    case fieldTypes.audiences:
      return serializeAudiences(value);
    case fieldTypes.location:
      return serializeLocation({ paramName, value });
    case fieldTypes.checkboxList:
    case fieldTypes.pillPickerMultiple: {
      // Legacy support for single values
      const valueArr = Array.isArray(value) ? value : [value];
      return { [paramName]: valueArr.join(',') };
    }
    default:
      return { [paramName]: value };
  }
};

const filterOutEmptyParams = (params) => Object.keys(params).reduce((result, paramName) => {
  const value = params[paramName];
  if (getIsFieldValueEmpty(value)) {
    return result;
  }

  return {
    ...result,
    [paramName]: value,
  };
}, {});

const serializeInsightsParams = (baseParams, panelParams) => {
  const filteredBaseParams = filterOutEmptyParams(baseParams);
  const filteredPanelParams = filterOutEmptyParams(panelParams);

  const combined = { ...filteredBaseParams };

  Object.keys(filteredPanelParams).forEach((paramName) => {
    if (combined[paramName] && paramName === paramNames.audiences) {
      combined[paramName] = combined[paramName].concat(filteredPanelParams[paramName]);
    } else {
      combined[paramName] = filteredPanelParams[paramName];
    }
  });

  const combinedWithFilteredKeys = Object.keys(combined)
    .filter((paramName) => fieldByParamName[paramName])
    .reduce((obj, paramName) => {
      const result = { ...obj };
      const field = fieldByParamName[paramName];
      const value = combined[paramName];

      return {
        ...result,
        ...serializeValue({ fieldType: field.type, paramName, value }),
      };
    }, {});

  return qs.stringify(combinedWithFilteredKeys);
};

export default serializeInsightsParams;
